// Customizable Area Start
import React from "react";
import { Input, Button } from '@builder/component-library';
import { Box, Typography, styled,AppBar, Toolbar, Select, MenuItem, Checkbox } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {English, dutch, german, navLogo } from "./assets";

const languageOptions = [
  { value: 'en', label: 'English', image: English, alt: "en" },
  { value: 'de', label: 'German', image: german, alt: "de" },
  { value: 'nl', label: 'Dutch', image: dutch, alt: "nl" },
];
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
   renderHeader = ()=>{
    return  <HeaderContainer position="static">
    <HeaderToolbar>
      <HeaderLogo src={navLogo} alt="logo" />
      <CustomSelect
        value={this.state.language}
        inputProps={{ 'aria-label': 'Select Language' }}
      >
        {languageOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <img src={option.image} alt={option.alt} /> {option.label}
          </MenuItem>
        ))}
      </CustomSelect>
    </HeaderToolbar>
  </HeaderContainer>
   }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        {this.renderHeader()}
        <Main>
          <Body>
            <Header variant="h4" >
              {this.activationTitle}
            </Header>
            <SubHeader variant="h1">
              {this.activationHeader} {/*UI Engine::From Sketch*/}
            </SubHeader>
            <FormWrapper>
              <InputWrapper>
                <Label>{this.emailLabel}</Label>
                <Input
                  data-test-id="txtInputEmail"
                  placeholder={"Email"}
                  value={this.state.email}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>{this.passwordLabel}</Label>
                <Input
                  data-test-id="txtInputPassword"
                  secureTextEntry={this.state.enablePasswordField}
                  placeholder={this.passwordPlaceHolder}
                  value={this.state.password}
                  onChangeText={(text) => {
                    this.setPassword(text);

                  }}
                  rightIcon={
                    <Button
                      style={{ backgroundColor: "transparent" }}
                      data-test-id="btnTogglePassword"
                      aria-label="toggle password visibility"
                      onPress={this.handleClickShowPassword}
                      icon={this.state.enablePasswordField ? (
                        < VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    />
                  }
                />
              </InputWrapper>

              <CustomCheckbox>
              <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={this.state.checkedTermsAndConditions}
                    onChange={this.handleCheckbox}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />

                  <CheckBoxText>
                    {this.passwordCondition1}
                  </CheckBoxText>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    data-test-id={"btnRememberMe"}
                    style={{ margin: 1 }}
                    title=""
                    onChange={this.handleCheckbox}
                    checked={this.state.checkedTermsAndConditions}
                  />
                  <CheckBoxText> {this.passwordCondition2}</CheckBoxText>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    data-test-id={"btnRememberMe"}
                    title=""
                    style={{ margin: 1 }}
                    onChange={this.handleCheckbox}
                    checked={this.state.checkedTermsAndConditions}
                  />
                  <CheckBoxText> {this.passwordCondition3}</CheckBoxText>
                </Box>
                </CustomCheckbox>
              <Box display="flex" alignItems="center" margin="10px"  >
                <Checkbox
                  data-test-id={"btnRememberMe"}
                  title=""
                  style={{ margin: 5 }}
                  onChange={this.handleCheckbox}
                  checked={this.state.checkedTermsAndConditions}
                />
                <CheckBoxText sx={{ color: "#475569", fontSize: "14px" }}> {this.agreeWith}{" "}<StyledLink>{this.termsAndConditions}</StyledLink></CheckBoxText>
              </Box>
              <ButtonsWrapper>
                <Button
                  data-test-id={"btnEmailLogIn"}
                  style={{ backgroundColor: "#00496B", }}
                  text={this.btnText}
                />
                </ButtonsWrapper>
            </FormWrapper>
          </Body>
        </Main>
      </>
      // Customizable Area End
    );
  }

}

// Customizable Area Start


const HeaderLogo = styled('img')(({ theme }) => ({
  height: '40px',
  width: "100px",
  marginRight: theme.spacing(2),
}));

const HeaderContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  display:"flex",
  justifyContent:"space-between",
}));

const HeaderToolbar = styled(Toolbar)({
  display:"flex", justifyContent:"space-between",
})

const CustomSelect = styled(Select)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  '& .MuiSelect-select': {
    color: '#053348',
  },
  '& .MuiMenuItem-root': {
    color: '#64748B',
  },
}));

const Main = styled(Box)({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  background: "#E9F6FF",
  justifyContent: "center",
});

const Body = styled(Box)({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px 10px",
  borderRadius: "10px",
  width: "100%",
  maxWidth: "460px",
});

const Header = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "24px",
  marginBottom: "15px",
  color: "#053348",
  fontWeight: 800,
});

const SubHeader = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: 500,
  color: "#053348",
});

const FormWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  paddingTop: "20px",
  flexDirection: "column",
});

const InputWrapper = styled(Box)({
  flex: 1,
  padding: "10px 20px",
  flexDirection: "column",
});
const CustomCheckbox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  padding:"10px",
})
const CheckBoxText = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Roboto",
  color: "#053348",
});

const StyledLink = styled('a')(({ theme }) => ({
  color: '#00496B',
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none',
  },
}));

const Label = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "Roboto",
  color: "#053348",
  marginBottom: "5px",
});

const ButtonsWrapper = styled(Box)({
  flex: 1,
  padding: "20px",
});


// Customizable Area End

