import React from "react";

// Customizable Area Start
import { Input, Button } from '@builder/component-library';
import { Box, Typography, styled,AppBar,Select,Toolbar,MenuItem} from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { navLogo } from "./assets";
import  {
  configJSON
} from "./EmailAccountLoginController";
import { StyleSheet } from "react-native";

const MainContainer = styled(Box)({
  padding:"30px",
  gap:"80px",
  display: "flex",
  alignItems: "center",
  background:"#E9F6FF",
  justifyContent: "center",
});

const BodyContainer = styled(Box)({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px 10px",
  borderRadius:"10px",
  width: "100%",
  maxWidth: "460px",
});

const Header = styled(Typography)({
fontFamily: "Roboto",fontSize: "24px",marginBottom: "15px",color: "#053348",fontWeight: 800,
});

const SubHeader = styled(Typography)({
  fontFamily: "Roboto",fontSize: "16px",fontWeight:500,color: "#053348",
});

const FormWrapper = styled(Box)({
  display: "flex",width: "100%",marginTop:"30px",paddingTop: "20px",flexDirection: "column",
});

const InputWrapper = styled(Box)({
  flex: 1,
  padding:"10px 20px",
  flexDirection: "column",
});

const RemoveBackgroundButton = styled(Button)({
  backgroundColor: "transparent",
  padding: 0, 
  margin: 0,
});

const StyledLink = styled('a')(({ theme }) => ({
  color: '#00496B',
  cursor: 'pointer',
  fontFamily:'Roboto',
  padding:"0 30px",
  fontWeight:500,
  fontSize:'18px',
  textDecoration: 'none',
}));

const Label = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "Roboto",
  color: "#053348",
  marginBottom: "5px",
});

const ButtonsWrapper = styled(Box)({
  flex: 1,
  padding: "20px",
  marginTop:"80px",
});

const Logo = styled('img')(({ theme }) => ({
  height: '40px', width: "100px",
  marginRight: theme.spacing(2)
}));

const HeadContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
}));

const CustomToolbar = styled(Toolbar)({
  display:"flex", justifyContent:"space-between",
});

const CustomDropown = styled(Select)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '22px',
  fontFamily: 'Roboto', fontWeight: 400,
  '& .MuiSelect-select': {
    color: '#053348'
  },
  '& .MuiMenuItem-root': {
    color: '#64748B'
  },
}));

const languageOptions = [
  { label: 'English',value: 'en'},
  {label: 'German', value: 'de'}, 
  {label: 'Dutch', value: 'nl'},   
];
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderNavbar = () =>{
    return <HeadContainer
    position="static"
  >
    <CustomToolbar>
      <Logo src={navLogo} alt="logo"/>
      <CustomDropown
        inputProps={{
          'aria-label': 'Select Language'
        }}
       
        value={this.state.languages}
      >
        {
          languageOptions.map((option) => {
            return <MenuItem
              key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          }
          )}
      </CustomDropown>
    </CustomToolbar>

  </HeadContainer >
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
      {this.renderNavbar()}
      <MainContainer>
        <BodyContainer>
          <Header variant="h4" >
              {configJSON.title}
          </Header>
          <SubHeader variant="h1">
         {configJSON.loginHeader}{/*UI Engine::From Sketch*/}
          </SubHeader>
          <FormWrapper>
            <InputWrapper>
              <Label>{configJSON.emailLabel}</Label>
              <Input
                data-test-id="txtInputEmail"
                value={this.state.email}
                onChangeText={(text) => this.setEmail(text)}
                placeholder={configJSON.placeHolderemail}
              />
            </InputWrapper>      
            <InputWrapper>
              <Label>{this.passwordLabel}</Label>
              <Input
                data-test-id="txtInputPassword"
                secureTextEntry={this.state.enablePasswordField}
                placeholder={this.placeHolderPassword}
                value={this.state.password}
                onChangeText={(text) => this.setPassword(text)}
                rightIcon={
                  <RemoveBackgroundButton
                   style={{ backgroundColor: "transparent" }}
                    data-test-id="btnTogglePassword"
                    aria-label="toggle password visibility"
                    onPress={this.handleClickShowPassword}
                    icon={this.state.enablePasswordField ? (
                      < VisibilityIcon/>
                    ) : (
                      <VisibilityOffIcon/>
                    )}
                  />
                }
              />
            </InputWrapper>
            <Box display="flex" justifyContent="flex-end">
            <StyledLink>{configJSON.resetPassword}</StyledLink>
             </Box>
            <ButtonsWrapper>
              <Button
                data-test-id={"btnEmailLogIn"}
                style={{ backgroundColor: "#00496B", }}
                onPress={() => this.doEmailLogIn()}
                text={configJSON.btnTxtLogin}
              />
            </ButtonsWrapper>
          </FormWrapper>
        </BodyContainer>
      </MainContainer>
      </>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
